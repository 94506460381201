import Css from "./style.module.scss";

import { Route, Switch } from "react-router-dom";
import { checkAuthState, checkIsBusinessUser } from "selectors/user";
import {
  checkGlobalStatsFetching,
  getGlobalStats,
  getSelectedBusinessData, getSelectedBusinessId, getUserBusinessesData } from "selectors/businesses";
import { checkPreloaderShown } from "selectors/ui";
import { checkTasksFetching, getAutoTasksRawData } from "selectors/tasks";
import { getAccountsData } from "selectors/accounts";
import { getActiveOrganization } from "selectors/organizations";
import { useDispatch, useSelector } from "react-redux";
import AuthHandler from "nlib/common/AuthHandler";
import Businesses from "utils/Businesses";
import Countries from "const/Countries";
import DownloadAppModal from "mlib/common/DownloadAppModal";
import Header from "mlib/Header";
import LoginRedirect from "nlib/common/LoginRedirect";
import ModalsContainer from "nlib/common/ModalsContainer";
import Navbar from "mlib/Navbar";
import NoBusinessData from "./lib/NoBusinessData";
import Onboarding from "./lib/Onboarding";
import Preloader from "nlib/common/Preloader";
import React, { Suspense, lazy, useEffect } from "react";
import RedirectToDefaultRoute from "nlib/common/RedirectToDefaultRoute";
import TasksActions from "actions/TasksActions";
import UiActions from "actions/UiActions";
import UiRoutes from "const/UiRoutes";

const ROUTES = [
  [lazy(() => import("mlib/pages/AccountsPage")), `${UiRoutes.ACCOUNTS}`],
  [lazy(() => import("mlib/pages/TransactionsPage")), `${UiRoutes.TRANSACTIONS}/:transactionId?`],
  [lazy(() => import("mlib/pages/DocumentsPage")), `${UiRoutes.DOCUMENTS}/:documentId?`],
  [lazy(() => import("mlib/pages/TasksPage")), `${UiRoutes.TASKS}/:taskId?`],
  [lazy(() => import("mlib/pages/VaultPage")), `${UiRoutes.ARCHIVE}/:fileId?`],
  [lazy(() => import("mlib/pages/ActivityPage")), `${UiRoutes.ACTIVITY}`],
  [lazy(() => import("mlib/pages/DashboardPage")), `${UiRoutes.DASHBOARD}`]
];

const App = () => {
  const dispatch = useDispatch();

  const userAuthState = useSelector(checkAuthState);

  const activeOrganization = useSelector(getActiveOrganization);

  const businessesData = useSelector(getUserBusinessesData);

  const { extraData, settings } = useSelector(getSelectedBusinessData);

  const businessUser = useSelector(checkIsBusinessUser);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const preloaderShown = useSelector(checkPreloaderShown);

  const selectedBusinessData = useSelector(getSelectedBusinessData);

  const globalStatsFetching = useSelector(checkGlobalStatsFetching);

  const tasksFetching = useSelector(checkTasksFetching);

  const globalStats = useSelector(getGlobalStats);

  const accountsData = useSelector(getAccountsData);

  const autoTasksData = useSelector(getAutoTasksRawData);

  const { countryCode } = activeOrganization || {};

  useEffect(() => {
    dispatch(UiActions.togglePreloader(false));
  }, [dispatch]);

  useEffect(() => {
    if (selectedBusinessData.id && globalStats.transactions && !globalStatsFetching && !tasksFetching) {
      dispatch(TasksActions.checkAndUpdateAutoTasksStatus());
    }
  }, [
    businessUser,
    dispatch,
    autoTasksData.length,
    accountsData.length,
    globalStats.transactions,
    globalStats.documents,
    globalStats.audit,
    selectedBusinessData.id,
    globalStatsFetching,
    tasksFetching
  ]);

  if (preloaderShown) {
    return <Preloader fixed />;
  }

  return (
    <div className={Css.app}>
      <Suspense fallback={<Preloader fixed />}>
        <Switch>
          <Route path={UiRoutes.LOGIN}>{() => userAuthState ? <RedirectToDefaultRoute /> : <AuthHandler />}</Route>
          <Route path="/:businessId?">
            {({ match: { params: { businessId } } }) => {
              if (!userAuthState) return <LoginRedirect />;

              if (businessId && !businessesData.some(({ id }) => id === businessId)) {
                return <RedirectToDefaultRoute />;
              }

              if (!businessesData.length) {
                return (
                  <>
                    <Header />
                    <NoBusinessData />
                  </>
                );
              }

              if (businessId !== selectedBusinessId) return <Preloader absolute />;

              const onboarding = countryCode !== Countries.CZ
                && !businessUser && Businesses.checkIsOnboarding(extraData, settings);

              return (
                <>
                  <Header />
                  {onboarding
                    ? <Onboarding />
                    : (
                      <>
                        <Switch>
                          {ROUTES.map(([PageComponent, route]) => (
                            <Route
                              key={`${route}.${businessId}`}
                              path={`/:businessId${route}`}
                              component={PageComponent} />
                          ))}
                          <Route><RedirectToDefaultRoute /></Route>
                        </Switch>
                        <DownloadAppModal />
                        <Navbar />
                      </>
                    )}
                </>
              );
            }}
          </Route>
        </Switch>
      </Suspense>
      <ModalsContainer />
      <div className={Css.topOffCanvas} />
    </div>
  );
};

export default React.memo(App);
